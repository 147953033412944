import { CartViewObject, CustomerViewObject, HubConnectionViewObject, UserRole, UserViewObject } from '@/api';
import { enumToArray } from '@/core/constants';
import { computed, ComputedRef, WritableComputedRef } from 'vue';
import { Store } from '@/core/store/store';
import { mergeObject } from '@/core/util/mergeObjects';
import notificationsService from '@/core/notifications/notifications.service';
import bus from '@/core/bus';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { useLocalStorage } from '@vueuse/core';
import customerApiService from '@/core/api/controllers/customerApi.service';
import shopApiService from '@/core/api/controllers/shopApi.service';
import { debounce } from 'lodash-es';
import dictionary from '@/core/dictionary/dictionary';
import serverHubClient from '@/core/signalr/serverHub.client';

interface State extends Record<string, unknown> {
    identity: {
        user: UserViewObject | undefined | null;
    },
    activeCustomer: CustomerViewObject | undefined | null;
    activeCart: CartViewObject | undefined | null;
    cartUpdating: boolean;
}

let websiteVersion = '';
const roleArray = enumToArray(UserRole);
const activeCustomerId = useLocalStorage<string>(LocalStorageKeys.CUSTOMER, '');

class UserStore extends Store<State> {
    constructor() {
        super();
               
        bus.on('LOGGED_OUT', () => {
            this.clear();
        });

        bus.on('CustomerUpdated', (customer) => {
            if (this.state.activeCustomer?.id === customer.id) {
                mergeObject(this.state.activeCustomer, customer);
            }
        });

        bus.on('CustomersUpdated', (customers) => {
            for (let i = 0; i < customers.length; i++) {
                const customer = customers[i];

                if (this.state.activeCustomer?.id === customer.id) {
                    mergeObject(this.state.activeCustomer, customer);
                    break;
                }
            }
        });

        bus.on('CustomerCreated', (customer) => {
            if (this.state.activeCustomer?.id === customer.id) {
                mergeObject(this.state.activeCustomer, customer);
            }
        });

        bus.on('CustomerRemoved', (customerId) => {
            if (this.state.activeCustomer?.id === customerId) {
                this.activeCustomer.value = null;
            }
        });

        bus.on('LockCustomer', lock => {
            if (this.state.activeCustomer?.id === lock.entityId) {
                this.state.activeCustomer.lock = lock;
            }
        });

        bus.on('UnlockCustomer', lock => {
            if (this.state.activeCustomer?.id === lock.entityId) {
                this.state.activeCustomer.lock = null;
            }
        });

        bus.on('UsersAddedToCustomer', (accesses, customerId) => {
            if (this.state.activeCustomer?.id === customerId) {
                for (const access of accesses) {
                    const existing = this.state.activeCustomer.userAccesses.find(x => x.userId === access.userId);
                    if (existing) {
                        mergeObject(existing, access);
                    } else {
                        this.state.activeCustomer.userAccesses.push(access);
                    }
                }
            }
        });

        bus.on('UsersRemovedFromCustomer', (userIds, customerId) => {
            if (this.state.activeCustomer?.id === customerId) {
                for (const userId of userIds) {
                    const index = this.state.activeCustomer.userAccesses.findIndex(x => x.userId === userId);
                    if (index >= 0) {
                        this.state.activeCustomer.userAccesses.splice(index, 1);
                    }
                }
            }
        });
        
        serverHubClient.addEvent('ValidateVersion', (version: string) => {
            if (websiteVersion && websiteVersion !== version) {
                notificationsService.notify({
                    title: 'Website opdatering',
                    message: 'Der er kommet en ny version af websitet. Siden bliver reloadet om 5 sekunder.',
                    duration: 0,
                });

                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }

            websiteVersion = version;
        });

        bus.on('UserUpdated', user => {
            if (this.state.identity.user?.id === user.id) {
                mergeObject(this.state.identity.user, user);
            }
        });

        bus.on('UsersUpdated', users => {
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                if (this.state.identity.user?.id === user.id) {
                    mergeObject(this.state.identity.user, user);
                    return;
                }
            }
        });

        bus.on('UserCreated', user => {
            if (this.state.identity.user?.id === user.id) {
                mergeObject(this.state.identity.user, user);
            }
        });

        serverHubClient.addEvent('OnConnected', (userId: string, connection: HubConnectionViewObject) => {
            if (this.state.identity.user && this.state.identity.user.id === userId) {
                (this.state.identity.user.activeConnections ??= []).push(connection);
            }
        });

        serverHubClient.addEvent('OnConnectionUpdate', (userId: string, connection: HubConnectionViewObject) => {
            if (this.state.identity.user && this.state.identity.user.id === userId) {
                if (!this.state.identity.user.activeConnections?.length) {
                    this.state.identity.user.activeConnections = [connection];
                } else {
                    const existingConnection = this.state.identity.user.activeConnections.find(x => x.connectionId === connection.connectionId);
                    if (existingConnection) {
                        mergeObject(existingConnection, connection);
                    } else {
                        this.state.identity.user.activeConnections.push(connection);
                    }
                }
            }
        });

        serverHubClient.addEvent('OnDisconnected', (userId: string, connectionId: string) => {
            if (this.state.identity.user?.activeConnections?.length && this.state.identity.user.id === userId) {
                const index = this.state.identity.user.activeConnections.findIndex(x => x.connectionId === connectionId);
                if (index >= 0) {
                    this.state.identity.user.activeConnections.splice(index, 1);
                }
            }
        });
    }
    
    protected data() {
        return {
            identity: {
                user: undefined,
            },
            activeCustomer: null,
            activeCart: null,
            cartUpdating: false,
        };
    }

    public hasRole(role: UserRole) {
        return !!this.state.identity.user && roleArray.indexOf(this.state.identity.user.role) >= roleArray.indexOf(role);
    }

    public get isAdministrator(): ComputedRef<boolean> {
        return computed(() => this.hasRole(UserRole.Administrator));
    }

    public setUser(user: UserViewObject): void {
        this.state.identity.user = user;

        if (activeCustomerId.value) {
            customerApiService
                .getCustomer(activeCustomerId.value)
                .then(result => {
                    if (result) {
                        if (user.role === UserRole.Administrator || result.customer.userAccesses.some(x => x.userId === user.id))
                            this.activeCustomer.value = result.customer;
                        else
                            activeCustomerId.value = undefined;
                    }
                });
        }
    }

    public get isLoggedIn(): ComputedRef<boolean> {
        return computed(() => !!this.state.identity.user);
    }

    public get user(): ComputedRef<UserViewObject | undefined | null> {
        return computed(() => this.state.identity.user);
    }

    public get activeCustomer(): WritableComputedRef<CustomerViewObject | null | undefined> {
        return computed({
            get: () => this.state.activeCustomer,
            set: async(c) => {
                if (this.state.cartUpdating)
                    return;
                
                this.state.cartUpdating = true;

                try {
                    const customerChanged = this.state.activeCustomer && c && this.state.activeCustomer.id !== c.id;

                    this.state.activeCustomer = c;
                    activeCustomerId.value = c?.id;

                    if (c) {
                        if (this.state.identity.user)
                            this.state.activeCart = await shopApiService.getCart();
                        else {
                            this.state.activeCart = null;
                            this.state.activeCustomer = null;
                        }
                    }

                    if (customerChanged) {
                        await serverHubClient.reconnect(true);
                    }

                } finally {
                    this.state.cartUpdating = false;
                }
            },
        });
    }

    public get canManageCustomerAssets(): ComputedRef<boolean> {
        return computed(() => {
            const access = this.state.activeCustomer?.userAccesses.find(x => x.userId === this.state.identity.user?.id);
            if (access) {
                return access.allowUploadingSecurityFiles;
            }

            return false;
        });
    }

    public get cart(): WritableComputedRef<CartViewObject | undefined | null> {
        return computed({
            get: () => this.state.activeCart,
            set: (c) => {
                this.state.activeCart = c;
                this.updateCartDebounce(false);
            },
        });
    }

    public get cartUpdating(): ComputedRef<boolean> {
        return computed(() => this.state.cartUpdating);
    }

    public async clearBasket() {
        this.state.cartUpdating = true;

        await shopApiService.clearCart();
        
        this.state.activeCart = null;
        this.state.cartUpdating = false;
    }

    public async reOrder(orderId: string) {
        if (this.state.cartUpdating || !this.user.value || !this.activeCustomer.value) 
            return;
    
        this.state.cartUpdating = true;

        const result = await shopApiService.reOrder(orderId);

        this.state.cartUpdating = false;

        return result;
    }

    public addLineItems(lineItems: { productId: string, amount: number }[], mode: 'Replace' | 'Append') {
        if (this.state.cartUpdating || !this.user.value || !this.activeCustomer.value) 
            return;
    
        lineItems.forEach(l => this.addOrUpdateLineItem(l.productId, l.amount, mode));
    }

    public addOrUpdateLineItem(productId: string, amount: number, mode: 'Replace' | 'Append'): number {
        amount = Math.min(Math.max(0, amount), 999);
        
        if (this.state.cartUpdating || !this.user.value || !this.activeCustomer.value) 
            return amount;

        if (!this.state.activeCart) {         
            if (amount > 0) {
                this.state.activeCart = { created: '', customerId: this.activeCustomer.value.id, id: '', lastAccessed: '', lineItems: [{ amount: amount, cartId: '', productId: productId }], receiverAddress: { addressId: '', city: '', country: 'Denmark', name: '', postalCode: '', street: '' } };
            } else 
                return amount; // No need to update changes to server as nothing valid has happened
        }
        else 
        {
            if (amount > 0) {
                const lineItem = this.state.activeCart.lineItems.find(x => x.productId === productId);
                if (lineItem) {
                    if (mode === 'Replace')
                        lineItem.amount = amount;
                    else if (mode === 'Append')
                        lineItem.amount = Math.min(999, lineItem.amount + amount);
                } else {
                    this.state.activeCart.lineItems.push({ amount: amount, cartId: '', productId: productId });
                }
            } else {
                const index = this.state.activeCart.lineItems.findIndex(x => x.productId === productId);
                if (index >= 0) {
                    this.state.activeCart.lineItems.splice(index, 1);
                }
            }
        }

        this.updateCartDebounce(true);
        return amount;
    }

    public saveCart(lineItemsOnly: boolean = false) {
        if (!this.state.activeCart || this.state.cartUpdating) 
            return;

        this.updateCartDebounce(lineItemsOnly);
    }

    private updateCartDebounce = debounce(async(lineItemsOnly: boolean) => {
        if (!this.state.activeCart) 
            return;

        this.state.cartUpdating = true;

        const result = lineItemsOnly 
            ? await shopApiService.addOrUpdateLineItems({ lineItems: this.state.activeCart.lineItems })
            : await shopApiService.addOrUpdateCart(this.state.activeCart);

        if (result) {
            if (lineItemsOnly) 
                this.state.activeCart.lineItems = result.lineItems;
            else
                mergeObject(this.state.activeCart, result);
        }
        
        this.state.cartUpdating = false;
    }, 1000);

    public async submitOrder() {
        if (!this.state.activeCart || this.state.cartUpdating || !this.cart.value?.lineItems?.length) return;

        this.state.cartUpdating = true;

        await this.updateCartDebounce.flush();

        const result = await shopApiService.submitOrder();
        if (result) {
            this.state.activeCart = null;

            notificationsService.notify({
                title: dictionary.get('SubmitOrder.Success.Title'),
                message: dictionary.get('SubmitOrder.Success.Message'),
                duration: 5000,
            });
        }
        
        this.state.cartUpdating = false;

        return result;
    }

    public get activeConnection(): ComputedRef<HubConnectionViewObject | null | undefined> {
        return computed(() => this.state.identity.user?.activeConnections?.find(x => x.connectionId === serverHubClient.connectionId.value));
    }

    public get isStaff(): ComputedRef<boolean> {
        return computed(() => this.state.identity.user?.isStaff ?? false);
    }

    public clear(): void {
        this.state.identity.user = undefined;
        this.state.activeCustomer = undefined;
        activeCustomerId.value = '';
    }
}

export const userStore = new UserStore();
